import React from 'react'

import Typography from 'Clutch/Atoms/Typography'
import TextLink from 'Clutch/Atoms/TextLink'
import Svg from 'Clutch/Atoms/Svg'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import FeatureFlagWrapper from 'Clutch/UtilityComponents/FeatureFlagWrapper'

import styles from './badges.module.scss'

const Badges = ({ secondaryShippingDetails, secondaryDetailsIcon, isReturnable, isFreightShip, isMadeToOrder, isHazardous, isShippedFullyEnclosedInCardboard }) => {

  const secondaryShippingDetailsMessage = isShippedFullyEnclosedInCardboard ?
    <FeatureFlagWrapper ff={"Additional_Handling_Shipping_Experiment"}>
      <div className={styles.badge}>
        <Svg className={styles.icon} icon={secondaryDetailsIcon} size={1.25} />
        <div className={styles.badge_text}>
          <Typography className={styles.badge_text} inline size={0.875} font={'bold'} tone={'subtle'}>{secondaryShippingDetails}</Typography>
          <TextLink href={'/Info/Shipping'} className={styles.badge_subtext} targetBlank size={0.75} intent={'action'}>Shipping Policies & FAQs</TextLink>
        </div>
      </div>
    </FeatureFlagWrapper>
    :
    <div className={styles.badge}>
      <Svg className={styles.icon} icon={secondaryDetailsIcon} size={1.25} />
      <div className={styles.badge_text}>
        <Typography className={styles.badge_text} inline size={0.875} font={'bold'} tone={'subtle'}>{secondaryShippingDetails}</Typography>
        <TextLink href={'/Info/Shipping'} className={styles.badge_subtext} targetBlank size={0.75} intent={'action'}>Shipping Policies & FAQs</TextLink>
      </div>
    </div>;

  return (
    <ErrorBoundary>
      <div className={styles.badges}>
        {(secondaryShippingDetails && secondaryShippingDetails !== "") ? secondaryShippingDetailsMessage : false}
        {isHazardous ?
          <div className={styles.badge}>
            <Svg className={styles.icon} icon={'warning'} size={1.25} />
            <div className={styles.badge_text}>
              <Typography className={styles.badge_text} inline size={0.875} font={'bold'} tone={'subtle'}>Hazardous Item</Typography>
              <Typography size={0.75} tone={'subtle'}>This item disqualifies free shipping</Typography>
              <TextLink href={'/Info/Shipping'} className={styles.badge_subtext} targetBlank size={0.75} intent={'action'}>Shipping Policies & FAQs</TextLink>
            </div>
          </div>
          :
          false
        }
        {isMadeToOrder ?
          <div className={styles.badge}>
            <Svg className={styles.icon} icon={'made_to_order'} size={1.25} />
            <div className={styles.badge_text}>
              <Typography className={styles.badge_text} inline size={0.875} font={'bold'} tone={'subtle'}>Made to Order</Typography>
              <Typography size={0.75} tone={'subtle'}>This custom item will ship when completed</Typography>
            </div>
          </div>
          :
          false
        }
        {isFreightShip ?
          <div className={styles.badge}>
            <Svg className={styles.icon} icon={'truck_shipment'} size={1.25} />
            <div className={styles.badge_text}>
              <Typography className={styles.badge_text} inline size={0.875} font={'bold'} tone={'subtle'}>Truck Shipment</Typography>
              <Typography size={0.75} tone={'subtle'}>Truck shipments must be pre-paid with a credit card, certified check or money order</Typography>
            </div>
          </div>
          :
          false
        }
        <div className={styles.badge}>
          <Svg className={styles.icon} icon={'help'} size={1.25} />
          <div className={styles.badge_text}>
            <div className={styles.need_help_text}>
              <Typography className={styles.badge_text} size={0.875} font={'bold'} tone={'subtle'}>Need help? </Typography>
              <TextLink href={'tel:18553139176'} inline size={0.875} intent={'action'}> 855.313.9176</TextLink>
            </div>
            <TextLink href={'/info/helpcenter'} className={styles.badge_subtext} targetBlank size={0.75} intent={'action'}>View the Help Center</TextLink>
          </div>
        </div>
        {isReturnable ?
          <div className={styles.badge}>
            <Svg className={styles.icon} icon={'box'} size={1.25} />
            <div className={styles.badge_text}>
              <Typography className={styles.badge_text} inline size={0.875} font={'bold'} tone={'subtle'}>Quick and Easy Returns</Typography>
              <TextLink href={'/Info/ReturnsAndExchanges'} className={styles.badge_subtext} targetBlank size={0.75} intent={'action'}>Return Policy</TextLink>
            </div>
          </div>
          :
          <div className={styles.badge}>
            <Svg className={styles.icon} icon={'box'} size={1.25} />
            <div className={styles.badge_text}>
              <Typography size={0.875} font={'bold'} tone={'subtle'}>Ineligible for Return</Typography>
              <Typography size={0.75} tone={'subtle'}>Contains items that cannot be returned</Typography>
            </div>
          </div>
        }
      </div>
    </ErrorBoundary>
  )
}

export default Badges