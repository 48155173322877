import React, { Fragment, useEffect, useState } from 'react'

import Typography from 'Clutch/Atoms/Typography'


import { useProductPageStore } from '../../../contexts/ProductPageStore'
import RestRequest from "Utilities/RestClient/RestClient";
import useFeatureFlag from 'Clutch/Hooks/useFeatureFlag'
import styles from './promodisplay.module.scss'

import Card from "Clutch/Atoms/Card"
import TextLink from 'Clutch/Atoms/TextLink'
import Expiration from './expiration';

const PromoDisplay = () => {
  const [promo, setPromo] = useState(null);

  const isPurchaseable = useProductPageStore(x => x.isPurchaseable);
  const updatePricesForPromo = useProductPageStore(x => x.updatePricesForPromo)
  const isLazyDataHydrated = useProductPageStore(x => x.isLazyDataHydrated)
  const skuVariantNumber = useProductPageStore(x => x.skuVariantNumber);
  const skuBaseNumber = useProductPageStore(x => x.skuBaseNumber);
  const setPromoInContext = useProductPageStore(x => x.setPromoInContext);

  const featureFlag = useFeatureFlag("PromotionPreview_PDP_Frontend");

  const sendSegmentEvents = (segmentPromotionViewedEvents) => {
    try {
      segmentPromotionViewedEvents?.forEach(x => {
        analytics.track(x.name, x.properties)
        console.log(`${x.name}: ${JSON.stringify(x.properties)}`)
      });

    } catch (err) {
      console.error("error sending segment events", err)
    }

  }


  const fetchPromo = async () => {
    if (!isLazyDataHydrated) {
      return
    }
    if (!isPurchaseable) {
      return;
    }
    if (!featureFlag) {
      return;
    }
    var promoResponse = await RestRequest(`/api/checkout/promotionpreview/singleitem`, null, { skuVariantNumber, skuBaseNumber });
    if (promoResponse?.anythingToDisplay) {





      if (window.location.href.includes(`localhost`)) {
        promoResponse.expiresOn = new Date().setHours(new Date().getHours() + 10)
      }

      sendSegmentEvents(promoResponse.segmentPromotionViewedEvents)

      if (promoResponse.priceAfterPromo.listPriceSavings.dollars.decimal !== 0) {
        updatePricesForPromo({
          strikeThroughPrice: promoResponse.priceAfterPromo.strikeThroughPrice.string,
          price: promoResponse.priceAfterPromo.listPrice.string,
          retailLowPrice: promoResponse.priceAfterPromo.listPrice.string,
          savingsPrice: promoResponse.priceAfterPromo.listPriceSavings.dollars.string
        })
      }

    }
    setPromoInContext(promoResponse);
    setPromo(promoResponse);
  }


  useEffect(() => {
    fetchPromo();
  }, [skuVariantNumber, isLazyDataHydrated, featureFlag, isPurchaseable])

  if (!promo || !promo.anythingToDisplay) {
    return null;
  }


  return <Card isAccent intent={`positive`} noPadding className={styles.container} >
    <Expiration expiresOn={promo.expiresOn} />
    <Typography font={'bold'} size={1.25}>{promo.title}</Typography>
    <SubtitleRow promo={promo} />
    <Typography size={0.75} tone={'subtle'}>{promo.appliedMessage}</Typography>

  </Card>

}

const SubtitleRow = ({ promo, size = 0.875 }) => {
  return <Typography size={size}>{promo.subTitle} <FreeItems freeItems={promo.freeGifts} size={size} /> </Typography>
}

const FreeItems = ({ freeItems, size }) => {
  if (!freeItems || !freeItems.length) {
    return null;
  }
  return <>
    {freeItems.map(x => {
      return <Typography size={size} as={`span`}>{x.incentiveTitlePrefix}<TextLink intent={`action`} size={size} href={x.productPageUrl}>{x.incentiveLinkText}</TextLink></Typography>
    })}

  </>
}









export default PromoDisplay