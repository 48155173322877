import Typography from 'Clutch/Atoms/Typography'
import GetTimeAgoString from 'Clutch/Utilities/DateTime/TimeAgo';
import { formatDateWithOrdinal } from './dateutils';
import { useEffect, useState } from 'react';
import Svg from 'Clutch/Atoms/Svg'
import styles from './promodisplay.module.scss'

const Expiration = ({ expiresOn }) => {
  if (!expiresOn) {
    return null;
  }


  const expiresOnDate = new Date(expiresOn);

  const oneDay = 24 * 60 * 60 * 1000;

  const diffDays = Math.abs((expiresOnDate - new Date()) / oneDay);

  if (diffDays < 0) {
    return null;
  }

  if (diffDays > 180) {
    return null;
  }

  if (diffDays > 14) {
    return <ExpiresInRow size={0.875} intent={`warning`} >
      <>on {formatDateWithOrdinal(expiresOnDate, false)}</>
    </ExpiresInRow>

  }

  if (diffDays > 2) {
    return <ExpiresInRow size={0.875} intent={`warning`} >
      <>{GetTimeAgoString(expiresOn)} <Typography as={`span`} size={0.875} tone={`subtle`}>({formatDateWithOrdinal(expiresOnDate)})</Typography> </>
    </ExpiresInRow>
  }

  return <CountDown expiresOnDate={expiresOnDate} />

}
const CountDown = ({ expiresOnDate }) => {
  const [displayMessage, setDisplayMessage] = useState(null)

  const formatNumber = (number) => {
    return number.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })
  }

  const setMessage = () => {
    let milli = expiresOnDate - new Date();
    const seconds = Math.round(milli / 1000);

    const hours = Math.floor(seconds / 3600)
    let remainingSeconds = seconds % 3600
    const minutes = Math.floor(remainingSeconds / 60)
    remainingSeconds = remainingSeconds % 60

    setDisplayMessage(`${hours}:${formatNumber(minutes)}:${formatNumber(remainingSeconds)}`)

  }

  useEffect(() => {
    setMessage()
    setInterval(
      setMessage,
      1000
    )
  }, [])

  return <ExpiresInRow intent={`warning`} >
    in {displayMessage}
  </ExpiresInRow>

}


const ExpiresInRow = ({ children, size = 1, intent = null }) => {
  return <Typography font={"bold"} className={styles.expirationContain} intent={intent} size={size}><Svg size={size} intent={intent} icon={`timer`}></Svg> Offer expires {children}</Typography>

}

export default Expiration