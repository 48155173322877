import React, { useContext, useEffect } from 'react'
import Image from 'next/image'
import { useInView } from 'react-intersection-observer'

import { TrackingContext } from 'Clutch/Contexts/TrackingContext/TrackingContext'
import { useUserStore } from "Clutch/Stores/UserStore/UserStore";
import { useProductPageStore } from '../../contexts/ProductPageStore';
import { getSuperMarketAffinity } from 'Clutch/Utilities/Instrumentation/SegmentUtilities/segmentFunctions'

import Typography from 'Clutch/Atoms/Typography'
import TextLink from 'Clutch/Atoms/TextLink'
import Card from 'Clutch/Atoms/Card'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import Divider from 'Clutch/Atoms/Divider'
import Rating from 'Clutch/Atoms/Rating'

import styles from './asideRecommendations.module.scss'

const SpeedwayAlternateRecommendations = ({ }) => {

  const speedwayAlternativeProducts = useProductPageStore(x => x.speedwayAlternativeProducts);

  const { segmentReady } = useContext(TrackingContext)
  const userVin = useUserStore((x) => x.context.userVin);
  const userGuid = useUserStore((x) => x.context.userGuid);

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: '0px 0px', //because this doesn't initiate a request
  });

  const title = 'Speedway Motors Alternatives'

  useEffect(() => {
    if (inView) {
      //send segment product list viewed
      //this feeds miso recommendation impressions
      if (segmentReady && (speedwayAlternativeProducts || []).length > 0) {
        analytics.track('Product List Viewed', {
          'user_vin': `${userVin()}`,
          'web_user_guid': `${userGuid}`,
          'list_id': `pdp_aside_alts_1`,
          'category': `${title}`,
          'products': (speedwayAlternativeProducts || []).map((alt, i) => {
            return {
              'product_id': alt.skuBaseNumber,
              'sku': alt.skuBaseNumber,
              'name': alt.title,
              'price': alt.price,
              'position': i,
              'category': alt.segmentCategorization,
              'url': alt.productPageUrl,
              'image_url': alt.imageUrl,
              'quantity': 1,
              'brand': alt.brand,
              'availability': alt.availability,
              'rating': alt.reviewCount ? alt.rating : 0,
              'reviews': alt.reviewCount,
              'audience_affinity': getSuperMarketAffinity(alt)
            }
          })
        })
      } else {
        console.info('analytics not defined')
      }
    }
  }, [inView, segmentReady])

  if (!(speedwayAlternativeProducts || []).length)
    return false

  return (
    <ErrorBoundary>
      <div id={'pdp_speedway_alternates'} className={styles.pdp_aside_cards} ref={ref}>
        <Card fill>
          <Typography className={styles.header} size={1.25} as={'h2'} font={'heading'}>
            {title}
          </Typography>
          <Typography size={0.875} >
            {`${speedwayAlternativeProducts.length} ${speedwayAlternativeProducts.length === 1 ? 'item' : 'items'}`}
          </Typography>
          <Divider className={styles.divider} />
          {speedwayAlternativeProducts.map((speedwayAlternativeProduct, index) => (
            <div className={styles.rec_wrapper} key={speedwayAlternativeProduct.skuBaseNumber || index}>
              <TextLink as={'div'} className={styles.rec_image_wrapper} href={speedwayAlternativeProduct.productPageUrl}>
                <Image
                  src={`https:${speedwayAlternativeProduct.imageUrl ? speedwayAlternativeProduct.imageUrl : '//content.speedwaymotors.com/OtherImages/missingimage2.jpg'}`}
                  alt={speedwayAlternativeProduct?.title || 'Product image'}
                  width={124}
                  height={124} />
              </TextLink>
              <span className={styles.content}>
                <TextLink size={1} href={speedwayAlternativeProduct.productPageUrl}>
                  {speedwayAlternativeProduct.title}
                </TextLink>
                <div className={styles.ratingContainer}>
                  <Rating ratingValue={speedwayAlternativeProduct.rating} />
                </div>
                <Typography size={1} font={'bold'}>
                  {speedwayAlternativeProduct.priceString}
                </Typography>
              </span>
            </div>
          ))}
        </Card>
      </div>
    </ErrorBoundary>
  )
}

export default SpeedwayAlternateRecommendations