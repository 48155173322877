
import React, { Fragment, useContext } from 'react'

import Typography from 'Clutch/Atoms/Typography'
import TextLink from 'Clutch/Atoms/TextLink'
import Area from 'Clutch/Molecules/Area'
import Button from 'Clutch/Molecules/Button'
import Form from 'Clutch/Atoms/Form'
import Svg from 'Clutch/Atoms/Svg'

import FeatureFlagWrapper from 'Clutch/UtilityComponents/FeatureFlagWrapper'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import Share from 'Molecules/Share/'

import { GetUserGuid } from 'Clutch/Utilities/CookieUtilties/UserCookieUtilities'
import { getSuperMarketAffinity } from "Utilities/Instrumentation/SegmentUtilities/segmentFunctions";

import styles from './atcForm.module.scss'
import styled from 'styled-components'
import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'
import { useProductPageStore } from '../../../contexts/ProductPageStore'
import { AddToCartExperienceContext } from 'Clutch/Contexts/AddToCartExperienceContext/AddToCartExperienceContext'
import LoyaltyPointsPreview from '../loyalty/loyaltypointspreview'

const AddToCartForm = ({ isWaiting, setIsWaiting }) => {

  const lastPurchaseDateForSku = useProductPageStore(x => x.lastPurchaseDateForSku);
  const errorIndexOptions = useProductPageStore(x => x.errorIndexOptions);
  const loadingIndexOptionSelection = useProductPageStore(x => x.loadingIndexOptionSelection);
  const freeShippingThreshold = useProductPageStore(x => x.freeShippingThreshold);
  const powerReviewsMerchantId = useProductPageStore(x => x.powerReviewsMerchantId);
  const powerReviewsMerchantGroupId = useProductPageStore(x => x.powerReviewsMerchantGroupId);
  const updateQuantityRequested = useProductPageStore(x => x.updateQuantityRequested);
  const isIndexOptionKit = useProductPageStore(x => x.isIndexOptionKit);

  const skuVariantNumber = useProductPageStore(x => x.skuVariantNumber);
  const skuBaseNumber = useProductPageStore(x => x.skuBaseNumber);
  const title = useProductPageStore(x => x.title);
  const productPageUrl = useProductPageStore(x => x.productPageUrl);
  const images = useProductPageStore(x => x.images);
  const segmentCategorization = useProductPageStore(x => x.segmentCategorization);
  const brand = useProductPageStore(x => x.brand);
  const retailLowPrice = useProductPageStore(x => x.retailLowPrice);
  const superMarketAffinity = useProductPageStore(x => x.superMarketAffinity);
  const rating = useProductPageStore(x => x.rating);
  const reviewCount = useProductPageStore(x => x.reviewCount);
  const availabilityEnum = useProductPageStore(x => x.availabilityEnum);
  const quantityRequested = useProductPageStore(x => x.quantityRequested);

  const addToCartExperienceContext = useContext(AddToCartExperienceContext)

  const item = {
    skuVariantNumber: skuVariantNumber,
    skuBaseNumber: skuBaseNumber,
    quantityRequested: parseInt(quantityRequested),
    title: title,
    images: images,
    segmentCategory: segmentCategorization,
    brand: brand?.brandName,
    price: retailLowPrice,
    url: productPageUrl,
    superMarketAffinity: superMarketAffinity,
    availability: availabilityEnum,
    rating: rating,
    reviewCount: reviewCount
  }

  const callToOrderEvent = {
    action: 'Call to Order',
    label: 'Product Details Page Offer Section'
  }

  const addToCartWrapper = (items) => {
    setIsWaiting(true)
    addToCartExperienceContext.addToCart(items).then(() => {
      console.log('items', JSON.stringify(items))
      setIsWaiting(false)
    })
  }

  return (
    <ErrorBoundary>
      <Fragment>
        <Form id={'pdp-add-to-cart-form'} onSubmit={() => { }}>
          <div className={styles.pdp_offer_qty_wrapper}>
            <Typography className={styles.pdp_offer_qty_text} size={1} font={'bold'} inline>
              Quantity
            </Typography>
            <Form.Quantity
              id={styles.pdp_offer_qty}
              defaultValue={quantityRequested}
              minValue={1}
              maxValue={999}
              onChange={(q) => { updateQuantityRequested(q) }}
            />
          </div>
          <Button
            className={styles.pdp_atc_btn}
            intent={'positive'}
            disabled={loadingIndexOptionSelection}
            size={'large'}
            fill
            segmentEvent={{
              event: 'Product Add Started',
              properties: {
                cart_id: GetUserGuid(),
                product_id: item.skuBaseNumber,
                sku: item.skuBaseNumber,
                name: item.title,
                variant: item.skuVariantNumber,
                quantity: item.quantityRequested,
                brand: item.brand,
                category: item.segmentCategory,
                price: item.price,
                url: item.url,
                image_url: (item.images || []).length ? `https:${item.images[0]}` : "",
                availability: item.availability,
                rating: item.reviewCount ? item.rating : 0,
                reviews: item.reviewCount,
                audience_affinity: getSuperMarketAffinity(item),
              }
            }}
            text={'Add to Cart'}
            isWaiting={isWaiting}
            onClick={(e) => {
              e.preventDefault()
              isIndexOptionKit
                ? addToCartExperienceContext.showKitDetailsDrawer({ atc: true })
                : !skuVariantNumber
                  ? errorIndexOptions()
                  : addToCartWrapper([item])
            }}
            data-testid={'pdp-add-to-cart'} />

          <LoyaltyPointsPreview />

          <Button
            className={styles.pdp_cto_btn}
            intent={'subdued'}
            brand={'secondary'}
            icon={'phone'}
            disabled={loadingIndexOptionSelection}
            size={'medium'}
            fill
            segmentEvent={createClickedSegmentEvent('CallToOrder')}
            href={'tel:18556829231'}
            text={'Need Help? Call to Order'}
            data-testid={'pdp-call-to-order'} />
          <FeatureFlagWrapper
            ff={"Free_Shipping_Text"}
            childrenIfFalse={false}
          >
            <div className={styles.pdp_offer_free_shiping_wrapper}>
              <Typography size={1} font={'bold'} inline>
                FREE SHIPPING <Typography font={'regular'} inline>over</Typography> ${freeShippingThreshold}
              </Typography>
            </div>
          </FeatureFlagWrapper>
        </Form>
        {lastPurchaseDateForSku ?
          <Area intent={'subdued'} className={styles.lastPurchased} icon={'star'}>
            <Typography className={styles.lastPurchased_header} font={'bold'}>
              {`Last purchased on ${lastPurchaseDateForSku}`}
            </Typography>
            <TextLink className={styles.lastPurchased_link} size={0.875} chevron={'right'} href={`/ReviewSubmission?` +
              `pr_page_id=${item.skuBaseNumber}` +
              `&pr_merchant_id=${powerReviewsMerchantId}` +
              `&pr_merchant_group_id=${powerReviewsMerchantGroupId}`}>
              Write a Review
            </TextLink>
          </Area> : false}
        <div id={'pdp-add-to-cart-button'} />
        <div className={styles.pdp_atc_aids}>
          <TextLink size={0.875}
            className={styles.pdp_add_to_list}
            data-testid={'pdp-add-to-list'}
            onClick={(e) => {
              e.preventDefault()
              isIndexOptionKit
                ? addToCartExperienceContext.showKitDetailsDrawer({ list: true })
                : !skuVariantNumber
                  ? errorIndexOptions()
                  : addToCartExperienceContext.showListDrawer([item])
            }} >
            <Svg size={0.875} tone={'subtle'} icon={'heart'} className={styles.pdp_atc_aid_icon} /> Add to a list
          </TextLink>

          <Share
            url={productPageUrl}
            subtitle={"Look what I found on Speedway Motors:"}
            title={title}
            tone={'subtle'}
            size={0.875}
            iconText={'Share'}
            className={styles.pdp_share}
            data-testid={'pdp-share'}
            as={'div'}
            segmentEvent={{
              event: 'Product Shared',
              properties: {
                share_via: 'link',
                // share_message: 'Hey, check out this item',
                // recipient: 'friend@example.com',
                product_id: skuBaseNumber,
                sku: skuBaseNumber,
                category: segmentCategorization,
                name: title,
                brand: brand?.brandName,
                variant: skuVariantNumber || undefined,
                price: retailLowPrice,
                url: productPageUrl,
                image_url: (images || []).length ? `https:${images[0]}` : "",
                availability: availabilityEnum,
                rating: reviewCount ? rating : 0,
                reviews: reviewCount,
                audience_affinity: getSuperMarketAffinity({ superMarketAffinity: superMarketAffinity }),
              }
            }}
          />
        </div>
      </Fragment>
    </ErrorBoundary>
  )
}

const StyledAreaIcon = styled.div`
    background-color: ${props => props.backgroundColor};
    padding: 0.5rem;
    border-radius: 100%;
    margin-right: 1rem;
`

export default AddToCartForm
