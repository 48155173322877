import React from 'react'
import ClassNames from 'classnames'

import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import Divider from 'Clutch/Atoms/Divider'
import Card from 'Clutch/Atoms/Card'
import Typography from 'Clutch/Atoms/Typography'
import { useProductPageStore } from '../../contexts/ProductPageStore'

import Description from './description/description'
import Specs from './specs/specs'
import Badges from './badges/badges'

import styles from './summary.module.scss'

const Summary = React.memo(({ }) => {
  const specs = useProductPageStore(x => x.specs)
  const subtitle = useProductPageStore(x => x.subtitle)
  const bullets = useProductPageStore(x => x.bullets)
  const toggleAccordion = useProductPageStore(x => x.toggleAccordion)
  const showWhatsInTheKitDrawer = useProductPageStore(x => x.showWhatsInTheKitDrawer)
  const rebates = useProductPageStore(x => x.rebates)
  const shippingEstimate = useProductPageStore(x => x.shippingEstimate)
  const isMadeToOrder = useProductPageStore(x => x.isMadeToOrder)
  const isKit = useProductPageStore(x => x.isKit)
  const isReturnable = useProductPageStore(x => x.isReturnable)
  const isFreightShip = useProductPageStore(x => x.isFreightShip)
  const isHazardous = useProductPageStore(x => x.isHazardous)
  const isShippedFullyEnclosedInCardboard = useProductPageStore(x => x.isShippedFullyEnclosedInCardboard)

  const displayingKeySpecs = specs && specs.length && specs.some(spec => spec.isKeySpec);
  const displayingProductDescription = subtitle || (bullets && bullets.length);

  return (
    <ErrorBoundary>
      {
        displayingProductDescription ?
          <div className={displayingKeySpecs ? styles.pdp_summary_desc : ClassNames(styles.pdp_summary_desc, styles.with_blank_section)}>
            <Card fill fillHeight noMargin>
              <Typography font={'bold'} tone={'contrast'} size={1.25} className={styles.pdp_summary_title} data-testid={'pdp-summary-details'}>
                Product Summary
              </Typography>
              <Divider />
              <Description toggleAccordion={toggleAccordion}
                isKit={isKit}
                showWhatsInTheKitDrawer={showWhatsInTheKitDrawer}
                subtitle={subtitle}
                bullets={bullets}
                hasRebates={(rebates || []).length > 0} />
            </Card>
          </div>
          :
          false
      }
      {
        displayingKeySpecs ?
          <div className={displayingProductDescription ? styles.pdp_summary_specs : ClassNames(styles.pdp_summary_specs, styles.with_blank_section, styles.leftmost_section)}>
            <Card fill fillHeight noMargin>
              <Typography font={'bold'} tone={'contrast'} size={1.25} className={styles.pdp_summary_title} data-testid={'pdp-summary-specs'}>
                Key Specs
              </Typography>
              <Divider />
              <Specs toggleAccordion={toggleAccordion} />
            </Card>
          </div>
          :
          false
      }

      <div className={displayingKeySpecs && displayingProductDescription ? styles.pdp_summary_badges
        : !displayingKeySpecs && !displayingProductDescription ? ClassNames(styles.pdp_summary_badges, styles.with_blank_section, styles.leftmost_section)
          : ClassNames(styles.pdp_summary_badges, styles.with_blank_section)}>
        <Card fill fillHeight noMargin>
          <Typography font={'bold'} tone={'contrast'} size={1.25} className={styles.pdp_summary_title} data-testid={'pdp-summary-badges'}>
            Need To Know
          </Typography>
          <Divider />
          <Badges
            isKit={isKit}
            showWhatsInTheKitDrawer={showWhatsInTheKitDrawer}
            secondaryShippingDetails={shippingEstimate?.secondaryShippingDetailsString ?? ""}
            secondaryDetailsIcon={shippingEstimate?.secondaryDetailsIcon ?? "box"}
            isReturnable={isReturnable}
            isFreightShip={isFreightShip}
            isMadeToOrder={isMadeToOrder}
            isHazardous={isHazardous} 
            isShippedFullyEnclosedInCardboard={isShippedFullyEnclosedInCardboard}
          />
        </Card>
      </div>
    </ErrorBoundary>
  )
})

export default Summary